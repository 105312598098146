import { Progress } from "antd";
import { useEffect, useState } from "react";
import AssignmenStatustDetails from "../AssignmentStatusDetails/AssignmentStatusDetails.component";
import "./GoalSection.styles.scss";
import { useQuery } from "react-query";
import api from "../../api/api";

const GoalSection = () => {
  const { data: crewPoint } = useQuery("crewPoint", api.getScoreInfo);
  const startPoint = crewPoint?.level_starting_points;
  const endPoint = crewPoint?.level_ending_points;

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPercent(() => (crewPoint?.crew_points / endPoint) * 100);
    }, 1000);
  }, [crewPoint, endPoint]);

  return (
    <div className="goal-and-taskStatusDetails">
      <div className="goal-section">
        <div className="dotted1"></div>
        <div className="dotted2"></div>
        <div className="dotted3"></div>
        <div className="progress-container">
          <Progress
            type="dashboard"
            percent={percent}
            gapDegree={80}
            strokeWidth={4}
            showInfo={false}
          />
          <div className="level-container">
            <h2 className="level">Level {crewPoint?.Crew_level}</h2>
            <p className="level-name">Professional</p>
          </div>
          <div className="points-progress">
            <span className="start-point">{startPoint}</span>
            <span className="end-point">
              {crewPoint?.crew_points}/{endPoint}
            </span>
          </div>
        </div>
      </div>
      <AssignmenStatustDetails crewPoint={crewPoint} />
    </div>
  );
};

export default GoalSection;
