const isEditProfilePage = () =>
  window.location.pathname.includes("edit-profile");
const savedState = JSON.parse(
  localStorage.getItem(
    `${isEditProfilePage() ? "profileFormState" : "hubFormState"}`
  )
);
const skippedItems = JSON.parse(
  localStorage.getItem(
    `${isEditProfilePage() ? "profileFormSkippedItems" : "hubFormSkippedItems"}`
  )
);

export const initialState = {
  currentStage: skippedItems?.[0]?.stage ?? 0,
  currentItem: skippedItems?.[0]?.item ?? 0,
  formValues: {},
  skippedItems: skippedItems ?? [],
};

export const ClickActions = {
  NEXT: "next",
  BACK: "back",
  RESETandSKIP: "reset-skip",
  NAVIGATE_TO_SKIPPED: "naviagte_to_skipped",
  NAVIGATE_TO_STAGE: "naviagte_to_stage",
  NAVIGATE_TO_FORM_ITEM: "naviagte_to_form_item",
};

const saveStateToLocalStorage = (state) => {
  localStorage.setItem(
    `${isEditProfilePage() ? "profileFormState" : "hubFormState"}`,
    JSON.stringify(state)
  );
};

export function handleClickReducer(state, action) {
  const stageItems = action?.stageItems;
  const stages = action?.stages;
  const values = action?.values;
  let newState;

  switch (action.type) {
    case ClickActions.NEXT: {
      console.log({ values });

      if (state.currentItem < stageItems?.length - 1) {
        newState = {
          ...state,
          currentItem: state.currentItem + 1,
          formValues: { ...values },
        };
      } else if (state.currentStage < stages?.length - 1) {
        const updatedSkippedItems = [...state?.skippedItems];
        const checkIfValueUndefined = Object.values(state?.formValues).some(
          (value) => value === undefined
        );
        console.log(checkIfValueUndefined);
        if (updatedSkippedItems.length > 0 && !checkIfValueUndefined) {
          updatedSkippedItems.shift();
          localStorage.setItem(
            `${
              isEditProfilePage()
                ? "profileFormSkippedItems"
                : "hubFormSkippedItems"
            }`,
            JSON.stringify(updatedSkippedItems)
          );
        }
        newState = {
          ...state,
          currentStage: state.currentStage + 1,
          currentItem: 0,
          formValues: { ...values },
          skippedItems: [...updatedSkippedItems],
        };
      } else {
        newState = { ...state };
      }
      break;
    }

    case ClickActions.BACK: {
      if (state.currentItem > 0) {
        newState = {
          ...state,
          currentItem: state.currentItem - 1,
          formValues: { ...values },
        };
      } else if (state.currentStage > 0) {
        newState = {
          ...state,
          currentStage: state.currentStage - 1,
          currentItem: stages[state?.currentStage - 1].formItems.length - 1,
          formValues: { ...values },
        };
      } else {
        localStorage.removeItem(
          `${isEditProfilePage() ? "profileFormState" : "hubFormState"}`
        );
        localStorage.removeItem(
          `${
            isEditProfilePage()
              ? "profileFormSkippedItems"
              : "hubFormSkippedItems"
          }`
        );
        return { ...state, shouldNavigateToPreviousPage: true };
      }
      console.log({ localStorage });
      break;
    }
    case ClickActions.RESETandSKIP: {
      console.log({ values });
      const skippedItems = [
        ...state.skippedItems,
        {
          stage: state.currentStage,
          item: state.currentItem,
        },
      ];
      localStorage.setItem(
        `${
          isEditProfilePage()
            ? "profileFormSkippedItems"
            : "hubFormSkippedItems"
        }`,
        JSON.stringify(skippedItems)
      );

      if (state.currentItem < stageItems?.length - 1) {
        newState = {
          ...state,
          currentItem: state.currentItem + 1,
          formValues: { ...values },
          skippedItems,
        };
      } else if (state.currentStage < stages?.length - 1) {
        newState = {
          ...state,
          currentStage: state.currentStage + 1,
          currentItem: 0,
          formValues: { ...values },
          skippedItems,
        };
      } else {
        newState = { ...state };
      }
      break;
    }

    case ClickActions.NAVIGATE_TO_SKIPPED: {
      newState = {
        ...state,
        currentStage: action.currentStage,
        currentItem: action.currentItem,
      };
      break;
    }

    case ClickActions.NAVIGATE_TO_STAGE: {
      newState = {
        ...state,
        currentStage: action.stageIndex,
        currentItem: 0,
      };
      break;
    }

    case ClickActions.NAVIGATE_TO_FORM_ITEM:
      return {
        ...state,
        currentStage: action.stageIndex,
        currentItem: action.itemIndex,
      };

    default:
      newState = { ...state };
      break;
  }
  saveStateToLocalStorage(newState);
  return newState;
}
