import { Divider, Spin } from "antd";
import AssignmentDetails from "../AssignmentDetails/AssignmentDetails.component";
import "./DashBoard.styles.scss";
import GoalSection from "../GoalSection/GoalSection.component";
import GroceryForm from "../Forms/GroceryForm/GroceryForm.component";
import GroceryList from "../GroceryList/GroceryList.component";
import api from "../../api/api";
import { useQuery } from "react-query";

const DashBoard = () => {
  const { data: unitChoices } = useQuery("unitChoices", api.getUnitChoices);
  const { data: userToDoAssignments } = useQuery(
    "userToDoAssignments",
    api.getUserToDoAssignments
  );

  const fetchSharedList = async () => {
    const response = await api.getSharedList();
    return response;
  };

  const { data: groceryList, isLoading } = useQuery(
    "sharedGroceryList",
    fetchSharedList
  );

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div className="dashboard-container">
      <h1 className="page-heading">Dashboard</h1>
      <div className="tasks-goalsection">
        <div className="task-list">
          <div className="task-type-heading">
            <span>Grocery List</span>
          </div>
          <GroceryList groceryList={groceryList} unitChoices={unitChoices} />
          <GroceryForm unitChoices={unitChoices} />
          <div className="task-type-heading">
            <span>My Tasks</span>
          </div>
          <div className="assignmentDetails">
            <AssignmentDetails
              assignments={userToDoAssignments?.to_do_assignments}
            />
          </div>
        </div>
        <Divider type="vertical" className="v-divider divider-color" />
        <Divider className="h-divider divider-color" />
        <div className="goal-container">
          <GoalSection />
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
