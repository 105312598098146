import { Card } from "antd";
import "./AssignmentStatusDetails.styles.scss";

const AssignmenStatustDetails = ({ crewPoint }) => {
  const assignmentStatusItems = [
    {
      id: 1,
      name: "Delayed Tasks",
      count: crewPoint?.tdt_count,
      addonClassName: "light-red",
    },
    {
      id: 2,
      name: "On Time Tasks",
      count: crewPoint?.tctot_count,
      addonClassName: "light-blue",
    },
    {
      id: 3,
      name: "Pending tasks",
      count: crewPoint?.pending_tasks_count,
      addonClassName: "red",
    },
    {
      id: 4,
      name: "Completed Tasks",
      count: crewPoint?.complete_tasks_count,
      addonClassName: "green",
    },
  ];
  return (
    <div className="assignmenStatusDetails-container">
      {assignmentStatusItems.map((assigmentStatus) => (
        <Card key={assigmentStatus.id} className="assignmentStatus-card">
          <div className={`border ${assigmentStatus.addonClassName}`}></div>
          <p className="name">{assigmentStatus.name}</p>
          <span className="count">{assigmentStatus.count}</span>
        </Card>
      ))}
    </div>
  );
};

export default AssignmenStatustDetails;
